import React from 'react';
// import PropTypes from 'prop-types';
import {
  Container,
  //  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
//  calendars
import 'react-dates/initialize';
import {
  DateRangePicker,
  // SingleDatePicker
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import RoomsGuestsSelector from '../roomsGuestsSelector';
import moment from 'moment';
// custom css
import './bookingInputBox.css';
import { navigate } from 'gatsby';
class bookingInputBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDateFocusedInput: null,
      filterDateStart: moment(new Date()),
      filterDateEnd: moment(new Date()).add(1, 'days'),
    };
  }

  dateFilterUpdated = (startDate, endDate) => {
    this.setState({ filterDateStart: startDate, filterDateEnd: endDate });
  };

  bookNowOnClick = () => {
    // window.location.href = 'https://www.booking.com/hotel/in/grace-galaxy.html';
    navigate('/book', {
      state: {
        checkin: moment(this.state.filterDateStart).format('YYYY-MM-DD'),
        checkout: moment(this.state.filterDateEnd).format('YYYY-MM-DD'),
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <div id="bookingInputBoxContainer">
            <Row>
              <Col
                style={{ marginBottom: '10px' }}
                xs={{ size: '12', offset: 0 }}
                sm={{ size: '12', offset: 0 }}
                md={{ size: '8', offset: 2 }}
                lg={{ size: '4', offset: 0 }}
              >
                {/* <SingleDatePicker
  date={this.state.filterDateStart} // momentPropTypes.momentObj or null
  onDateChange={filterDateStart => this.setState({ filterDateStart })} // PropTypes.func.isRequired
  focused={this.state.focused} // PropTypes.bool
  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
  id="CheckInInput" // PropTypes.string.isRequired,
/>
<SingleDatePicker
  date={this.state.filterDateEnd} // momentPropTypes.momentObj or null
  onDateChange={filterDateEnd => this.setState({ filterDateEnd })} // PropTypes.func.isRequired
  focused={this.state.focused} // PropTypes.bool
  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
  id="CheckOutInput" // PropTypes.string.isRequired,
/> */}
                <DateRangePicker
                  horizontalMargin="12"
                  orientation="vertical"
                  startDateId="startDate"
                  startDatePlaceholderText="Check In "
                  endDatePlaceholderText="Check Out "
                  endDateId="endDate"
                  startDate={this.state.filterDateStart}
                  endDate={this.state.filterDateEnd}
                  onDatesChange={({ startDate, endDate }) => {
                    this.dateFilterUpdated(startDate, endDate);
                  }}
                  focusedInput={this.state.filterDateFocusedInput}
                  onFocusChange={focusedInput => {
                    document.activeElement.blur();
                    this.setState({ filterDateFocusedInput: focusedInput });
                  }}
                />
              </Col>
              <Col
                style={{ marginBottom: '10px' }}
                xs={{ size: '12', offset: 0 }}
                sm={{ size: '12', offset: 0 }}
                md={{ size: '8', offset: 2 }}
                lg={{ size: '4', offset: 0 }}
              >
                <RoomsGuestsSelector />
              </Col>
              <Col
                style={{ marginBottom: '10px' }}
                xs={{ size: '12', offset: 0 }}
                sm={{ size: '12', offset: 0 }}
                md={{ size: '8', offset: 2 }}
                lg={{ size: '4', offset: 0 }}
              >
                <Button onClick={this.bookNowOnClick} id="BookNowButton">
                  Book Now
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default bookingInputBox;
